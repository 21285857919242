import Layout from '../components/Layout';
import Product from '../models/Product.js';
import db from '../utils/db';

import { useContext } from 'react';
import ProductItem from '../components/common/product/ProductBox1';
import { Col, Row } from 'reactstrap';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';

import { getAccount } from '../utils/account';
import Category from '../models/Category';
import { useTranslation } from 'react-i18next';
import FilterContext from '../utils/filter/FilterContext';
import StoreContext from '../utils/store/StoreContext';


export default function Home(props) {
  const { store } = useContext(StoreContext);
  const { productList } = props;
  const { t } = useTranslation('common');
  const filterContext = useContext(FilterContext);
  const setSelectedCategory = filterContext.setSelectedCategory;

  const updateCategory = (category) => {
    setSelectedCategory(category);
  };

  const layout = store.defaultLayout ?? 'grid-view';

  return (
    <Layout store={props.store}>
      <div>
        <div className="collection-product-wrapper">
          {productList.map((list) => (
            <Row key={list.slug} className="mt-5 mb-5">
              <Col>
                {(productList.length > 1 || list.slug != 'other') && (
                  <div className="title1">
                    <h2 className="title-inner1">
                      {list.slug == 'spotlight'
                        ? t('Highlights')
                        : list.slug == 'other'
                        ? t('Others')
                        : list.title}
                    </h2>
                    <div className="line">
                      <span></span>
                    </div>
                  </div>
                )}

                <div className={`product-wrapper-grid ${layout}`}>
                  <Row>
                    {list.items.map((product) => (
                      <Col
                        xs={layout == 'grid-view' ? 6 : 12}
                        lg={layout == 'grid-view' ? 3 : 6}
                        className="col-grid-box"
                        key={product._id}
                      >
                        <div className="product">
                          <div>
                            <ProductItem
                              product={product}
                              cartClass="cart-info cart-wrap"
                            ></ProductItem>
                          </div>
                        </div>
                      </Col>
                    ))}
                    {list.slug != 'spotlight' && !store.showAllProductsIndex && (
                      <Col
                        xs={layout == 'grid-view' ? 6 : 12}
                        lg={layout == 'grid-view' ? 3 : 6}
                        className="col-grid-box more-products"
                        key="more"
                      >
                        <a
                          href={`/search?category=${list.slug}`}
                          onClick={() => updateCategory(list.slug)}
                        >
                          <div>
                            <i className="fa fa-plus"></i>
                            <h4>{t('View More')}</h4>
                          </div>
                        </a>
                      </Col>
                    )}
                  </Row>
                </div>
              </Col>
            </Row>
          ))}
        </div>
      </div>
    </Layout>
  );
}

export async function getServerSideProps({ locale, req }) {
  const { accountId, hostname, store } = await getAccount(req);
  if (!accountId) {
    return {
      redirect: {
        permanent: false,
        destination: '/store-not-found',
      },
    };
  }
  await db.connect();

  const categories = await Category.find({
    accountId: accountId,
    deleted: false
  })
    .sort('name')
    .lean();
  let result = [];

  var queryStock = {};
  if (store.outStockProducts == 'n') {
    queryStock = {
      $or: [{ currentStock: { $gt: 0 } }, { stockControl: false }],
    };
  }

  let limit = 10;

  if (store.showAllProductsIndex) {
    limit = null;
  }

  const products = await Product.find({
    accountId: accountId,
    showCatalog: true,
    deleted: false,
    spotlight: true,
    enabled: true,
    slug: { $ne: null },
    ...queryStock,
  })
    .sort('name')
    .lean();
  if (products.length > 0) {
    result.push({
      items: products.map(db.convertDocToObj),
      title: 'Spotlight',
      slug: 'spotlight',
    });
  }

  for (var i in categories) {
    const category = categories[i];
    const products = await Product.find({
      accountId: accountId,
      showCatalog: true,
      deleted: false,
      enabled: true,
      categoryId: category._id,
      slug: { $ne: null },
      ...queryStock,
    })
      .sort('name')
      .limit(limit)
      .lean();
    if (products.length > 0) {
      let title = category.name;
      if(category.categoryId){
        const parentCategory = categories.find((c) => c._id == category.categoryId);
        if(parentCategory){
          title = parentCategory.name + ' - ' + title;
        }
      }
      result.push({
        items: products.map(db.convertDocToObj),
        title: title,
        slug: category.slug,
      });
    }
  }

  const productsNoCategory = await Product.find({
    accountId: accountId,
    showCatalog: true,
    deleted: false,
    categoryId: null,
    enabled: true,
    slug: { $ne: null },
    ...queryStock,
  })
    .sort('name')
    .limit(limit)
    .lean();
  if (productsNoCategory.length > 0) {
    result.push({
      items: productsNoCategory.map(db.convertDocToObj),
      title: 'Other',
      slug: 'other',
    });
  }

  await db.disconnect();
  return {
    props: {
      productList: result,
      accountId,
      hostname,
      store,
      ...(await serverSideTranslations(locale, ['common', 'status'])),
    },
  };
}
